#preloader {
  position: fixed;
  z-index: 999999999 !important;
  background-color: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
#preloader [data-loader="dual-ring"] {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  margin-top: -25px;
  display: inline-block;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 5px solid #2dbe60;
  border-color: #2dbe60 transparent #2dbe60 transparent;
  animation: dual-ring 1s linear infinite;
}

@keyframes dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
